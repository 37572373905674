.Menu__Item--bckg {
  cursor: pointer;
  user-select: none;
  margin-top: 1em;
  display: block;

  @media (min-width: 600px) {
    margin-top: 1em;
    display: inline;
  }
}