@import "../../Variables.less";

.About {
  margin: 10px 10px 0 10px;
  padding: 20px;
  width: auto;
  font-size: 17px;
  line-height: 23px;
  border-radius: 40px;
  transition: background-color .3s linear;

  &_dark {
    background-color: rgba(0, 0, 0, 0.45);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.6);
  }

  h1 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  @media (min-width: @mobileWidth) {
    width: 680px;
  }

  svg {
    height: 12px;
  }

  .p {
    margin-bottom: 5px;
  }

  hr {
    opacity: 0.42;
  }
}

.About > ul {
  margin-top: 12px;
}
