.Page__Github {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.Page__GithubItem {
    flex: 1 0 250px;
    margin-bottom: 20px;
    border-radius: 8px;
    background-color: rgba(255,255,255,0.04);
    transition: background-color .3s linear;
    margin-right: 20px;
    word-break: break-all;
    
    &:hover {
      background-color: rgba(255,255,255,0.09);
    }
    .Page__GithubItemInner {
      margin: 10px;
      line-height: 23px;
      
    }

    .IssuesCount{
      padding-left: 5px;
    }
  // TODO
  .centered-label {
    display: flex;
    align-items: center;
    > svg {
      margin-right: 5px;
    }
  }
}
